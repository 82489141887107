@import '../lib/colors';

.campaign-card {
  color: $vf-bluetext;

  p a {
    color: inherit;
    text-decoration: underline;

    &:hover, &:focus, &:active {
      color: $vf-blue;
    }
  }

  .campaign-progress {
    border-radius: 1rem;
    display: flex;
    height: 0.4rem;
    overflow: hidden; // force rounded corners by cropping it
    background-color: $gray-200;

    .campaign-progress-bar {
      background-color: $vf-bluetext;
      overflow: hidden;
    }
  }
}
