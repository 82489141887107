@import 'colors';

.alert {
  background: white;
  border: 2px solid white;
  border-radius: 5px;
  color: black;
  margin-bottom: 0;
  padding: .5rem 1.25rem 0.75rem;
  text-align: center;
}

.alert-warning {
  background: rgba($vf-blue, 0.1);
  border: 2px solid $vf-blue;
  color: $vf-blue;
}

.alert-danger {
  background: rgba($vf-red, 0.1);
  border: 2px solid $vf-red;
  color: $vf-red;
}

.alert-info {
  background: rgba($vf-green, 0.1);
  border: 2px solid $vf-green;
  color: $vf-green;
}

.alert p:last-of-type {
  margin-bottom: 0;
}
