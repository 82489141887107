// District View Styles
$statusBarHeight: 50px;
$statusBarRadius: $statusBarHeight*0.5;

.ribbon-wrapper {
  width: 85px;
  height: 88px;
  overflow: hidden;
  position: absolute;
  top: 1rem;
  right: 1rem;
  margin: -2px -2px 0 0;
}

.ribbon {
    font-size: 10px;
    color: #FFF;
    text-transform: uppercase;
    letter-spacing: .05em;
    line-height: 15px;
    text-align: center;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .4);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    position: relative;
    padding: 7px 0;
    right: -11px;
    top: 10px;
    width: 100px;
    height: 28px;
    -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, .3);
    box-shadow: 0 0 3px rgba(0, 0, 0, .3);
    background-color: $vf-blue;
    background-repeat: repeat-x;
}

.ribbon:before,
.ribbon:after {
    content: "";
    border-top: 3px solid #9e9e9e;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    position: absolute;
    bottom: -3px
}

.ribbon:before {
    left: 0
}

.ribbon:after {
    right: 0
}

.p-statusBar {
  background: $gray-300;
  border-radius: $statusBarRadius;
  height: $statusBarHeight;
  position: relative;

  .p-statusBar_bar {
    background: $vf-green;
    border-radius: $statusBarRadius 0 0 $statusBarRadius;
    height: 100%;
    line-height: $statusBarHeight;
    min-width: $statusBarRadius;
    padding-right: 1em;
    text-align: right;
  }
  
  .p-statusBar_bar.complete {
    border-radius: $statusBarRadius;
  }
  .p-statusBar_status {
    color: $gray-700;
    position: absolute;
    line-height: $statusBarHeight - 1;
    right: 30px;
    top: 0;
  }
}
