@import './lib/global';
@import "./lib/colors.scss";

.core-login .card-body .inner {
  padding: 0;
}

.masthead-login-widget {
  padding-left: 0px;
  padding-right: 0px;
}

.login-widget {
  padding-right: 2px;
  padding-left: 2px;
  max-width: 550px;
  margin: 0 auto;
  h4, .instructions {
    color: $vf-blue;
    text-align: left;
    font-weight: 700;
    font-size: 1.5rem;
  }
  .already-signed-up,
  .instructions {
    @include body-font;
    font-size: 1.2rem;
    font-weight: 700;
  }


}

@media (min-width: 480px) {
  .login-widget {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.login-widget .form-control {
  padding-right: 10px;
  padding-left: 10px;
}

.row.login-widget {
  margin-right: 0px;
  margin-left: 0px;
}

.btn-social-login {
  height: 3rem;
  border-radius: 30px;
  margin: 0.5rem 0 0.5rem 0;
  background-color: #262d7d;
  border-color: transparent;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  padding: 0rem .25rem 0rem .25rem;
  font-size: 1rem;
  color: #fbfbfd;
  transition: none;
  box-shadow: none;
  min-width: 225px;
  width: 100%;

  &:hover {
    background-color: #06509b;
  }
}

.social-button-column {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 0.5rem;

  @media (max-width: 1355px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.core-login p {
  font-size: 14px;
}

.core-login .card {
  align-self: center;
  display: block;
  margin-bottom: 1rem;
}

.core-login .card-body .inner {
  padding: 1rem;
}

.core-login .card-body {
  padding: 2rem;
}

.core-login .card-footer {
  text-align: center;
  font-weight: 700;
}

.core-login .card-footer p, .core-login .card-footer .btn-link {
  margin-bottom: 0;
  font-size: 1.2rem;
  color: #262D7D;
}

.btn-social-login img {
  margin-right: 1rem;
  height: 25px;
}

.email-signup {
  text-align: center;
  margin: 0 auto;
  text-align: left;
  color: #262d7d;
}

.email-signup {
  text-align: left;
  color: #0a2852;
}

.btn-login-link {
  font-size: 1.2rem;
  background-color: white;
  border-color: transparent;
  text-decoration: underline;
  font-weight: bold;
  color: #0056b3;
}

.login-widget .btn-link {
  font-weight: bold;
  color: #ff5c66;
  vertical-align: baseline;
  text-decoration: underline;
  padding-left: 4px;
}

.login-widget.form-control {
  border-radius: 4px;
  height: 2.5rem;
  border: 1px solid #262d7d;
}

.btn-signup {
  border-radius: 5px;
  line-height: 1;
  font-weight: 700;
  transition: none;
  padding: 0.75rem .75rem 1rem;
}

.signin-widget .email-signup {
  padding: 1rem 1rem 1rem 1rem;
}

@media (min-width: 640px) {
  .signin-widget .email-signup {
    padding: 1rem 10rem 1rem 10rem;
  }
}

.signin-widget label {
  text-align: center;
  width: 100%;
}

.signin-widget .form-control {
  margin: 0 auto;
}

.not-you {
  align-self: center;
  display: block;
  text-align: center;
  border-radius: 0rem;
  border: none;
  margin-right: 0rem;
  margin-left: 0rem;
  margin-top: 3rem;
}

@media (min-width: 400px) {
  .not-you {
    margin-right: 1rem;
    margin-left: 1rem;
  }
}

.email-signup {
  a.btn-primary-red {
    line-height: 22px;
  }
}
