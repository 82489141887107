@import '../lib/colors';

.support-vf {
  background-color: $vf-darkblue;
  color: white;
  text-align: center;

  small {
    display: inline-block;
    font-size: 0.9rem;
    line-height: 1.2;
  }
}
