.profile-nav-item {
  line-height: 35px;
  margin-top: -0.25rem;

  a {
    color: white;
    display: inline-block;
    height: 35px;
    width: 35px;
  }

  .profile-picture {
    background-color: rgba(255, 255, 255, 0.5);
    border: 3px solid white;
    border-radius: 18px;
    box-sizing: content-box;
    display: inline-block;
    height: 29px;
    overflow: hidden;
    width: 29px;

    &:not([src]) {
      visibility: hidden;
    }
  }
}
