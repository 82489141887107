@import '../lib/colors';
@import '../lib/headings';
@import '../lib/typography';

.instructions {
  color: $vf-bluetext;

  h1 {
    font-size: 50px;
    font-weight: bold;
  }

  h2 {
    color: #eb4d46;
    font-size: 30px;
    font-weight: bold;
    margin: 45px 0;
  }

  h3 {
    margin: 45px 0;
  }

  h4 {
    color: #76f7fc;
    font-family: Raleway, sans-serif;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: .2em;
    text-transform: uppercase;
  }

  p {
    font-family: Raleway, sans-serif;
    font-size: 17px;
    line-height: 26px;
  }

  a {
    color: #263079;
    font-weight: bold;
    text-decoration: underline;
  }

  a:hover {
    color: #007bff;
  }

  hr {
    border-bottom: 1px solid #d5d5d5;
  }

  .strikethrough {
    text-decoration: line-through;
    text-decoration-color: $vf-red;
    text-decoration-thickness: 12%;
  }

  li {
    font-family: Raleway, sans-serif;
    font-size: 17px;
    line-height: 26px;
  }

  .tip {
    border: 3px solid $vf-lightblue;
    min-height: 121px;

    img {
      height: 75px;
      left: 20px;
      position: absolute;
      top: 20px;
      width: 75px;
    }
  }

  .topics {
    background-color: #262d7d;
    padding: 40px 40px 0 40px;

    img {
      height: 100%;
      width: 100%;
    }

    ul {
      padding-inline-start: 0px;
    }

    a {
      color: #fff;
      padding-inline-start: 0px;
    }

    @media (max-width: 991px) and (min-width: 768px) {
      .col-md-6 {
        flex: auto;
        max-width: 100%;
        width: 100%;
      }
    }
  }

  .screenshot {
    width: 100%;
  }

  .sample {
    margin-top: 1rem;
    padding-inline-start: 40px;
  }
}
