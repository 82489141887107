@import '../lib/colors';

.choose-campaign-link {
  color: white;
  margin-bottom: 2rem;
  display: inline-block;
  font-size: small;

  a {
    color: white;
    margin-left: 1rem;
    text-decoration: underline;

    &:hover {
      color: $vf-lightblue;
    }
  }
}

.district-item,
.district-item.border {
  border-color: #263079 !important;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .2);
  color: #263079;
  position: relative;

  &:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .4);
  }
}

.district-item.closed {
  border-color: #7278a8 !important;
  color: #7278a8;
}

.district-item h3 {
  font-weight: bold;
}

.district-item p {
  margin-bottom: .5rem;
}

.district-item {
  h5 {
    margin-top: 1rem;
    margin-bottom: 0.75rem;
  }

  .adoption-info-list {
    margin-bottom: 1rem;

    p {
      margin-bottom: 0;
    }
  }

  .percent-message {
    display: inline-block;
    margin-left: auto;
    width: auto;
  }

  .progress {
    background-color: white;
    border: 2px solid $vf-blue;
    height: 2rem;
    padding: 2px;
    margin-left: -5px;
    margin-top: -5px;
    margin-bottom: 0.125rem;
    font-size: small;

    .progress-bar {
      background: repeating-linear-gradient(-67.5deg,
          $vf-blue,
          $vf-blue 20px,
          lighten($vf-blue, 10%) 20px,
          lighten($vf-blue, 10%) 40px,
        );
      border-radius: 1rem;
      margin: unset;
    }
  }
}

.district-item.closed .progress-bar {
  background-color: #ff5c66;
}

.district-item .district-item-content {
  margin-bottom: 2rem;
}

.district-item.closed .district-item-content {
  margin-bottom: 0;
}

.district-item .district-item-bottom-ribbon {
  background-color: #263079;
  border-color: #263079;
  bottom: 0;
  padding: 5px 0;
  position: absolute;
  width: 100%;
}

.district-item.closed .district-item-bottom-ribbon {
  background-color: #7278a8;
  border-color: #7278a8;
}

.district-item .mail-date {
  color: #ff5c66;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.district-item .district-item-cta {
  display: inline-block;
  float: right;
  margin-right: 15px;
}

.district-item .district-item-cta .btn {
  background-color: white;
  border-radius: 4.5rem;
  color: #263079;
  font-size: 12px;
  height: 25px;
  padding: .3rem .5rem;
}

@media (max-width: 1199px) and (min-width: 768px) {
  .district-item .adopted-ratio {
    font-size: 70%;
  }
}

.district-item-dashboard {
  .btn {
    border-radius: 40px;
    box-shadow: none;
    color: #fff;
    padding: 0.75rem 2rem;
    text-decoration: none;

    &.party {
      color: $vf-darkblue;
      background-color: $vf-red;
      border-color: $vf-red;

      &:hover {
        background-color: darken($vf-red, 5%);
        border-color: darken($vf-red, 5%);
      }

      &:active {
        box-shadow: inset 0 3px 0 darken($vf-red, 20%);
      }
    }
  }

  .alert {
    background-color: transparent;
    font-size: 14px;
  }

  .district-item-content {
    margin-bottom: 0;
    padding: 2.2rem 2.2rem 1.5rem;
  }

  .btn-district-item-campaigns-link {
    border-radius: 40px;
    box-shadow: none;
    padding: 0.75rem 2rem;
    text-decoration: none;
    color: $gray-800;
  }

  p {
    font-size: 1rem;
  }

  a {
    color: $vf-purple;
    cursor: pointer;
    text-decoration: underline;
  }

  .available-voter-count {
    margin: 0 0 0.5rem;
  }

  .highlight,
  .highlight a {
    color: $vf-red;
  }

  .district-item-dashboard-bottom {

    a,
    p {
      color: $vf-blue;
      font-weight: bold;
    }
  }

  &.closed,
  &.closed a {
    color: $vf-red;

    .district-item-dashboard-bottom {

      a,
      p {
        color: $vf-red;
      }
    }
  }
}

.MuiTabs-root.adoption {
  border-bottom: none;
  padding: 0;
  width: 100%;
  overflow-y: hidden;
  margin-bottom: -6px;

  .MuiTabs-flexContainer {
    display: inline-block;
    width: 100%;
  }

  .MuiTab-root {
    font-weight: bold;
    opacity: 100%;
    border-bottom: none;
    z-index: 0;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding-bottom: calc(1rem + 6px);
    padding-top: calc(1rem - 6px);
    width: calc(50% - 18px);
    border-top-width: 12px;
    border-top-style: solid;

    @media (max-width: 645px) {
      min-height: 5rem;
    }

    &.individual {
      color: white;
      background: $vf-blue;
      margin-right: 6px;
      margin-left: 12px;
      border-color: $vf-blue;
    }

    &.party {
      color: $vf-darkblue;
      background: $vf-red;
      margin-right: 12px;
      margin-left: 6px;
      border-color: $vf-red;
    }

    &.Mui-selected {
      background: white;
      z-index: 2;
      width: calc(50% - 6px);

      &.individual {
        color: $vf-blue;
        margin-left: 0px;
      }

      &.party {
        color: $vf-red;
        margin-right: 0px;
      }
    }
  }
}

.adopt-campaign-labels {
  width: auto;
  display: inline-block;
  margin-bottom: 1rem;
  padding: 0;
}

.adopt-message {
  display: inline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: large;
  margin-right: 1rem;
  width: auto;
}

.default-adoption-options-row {
  margin-bottom: 1rem;

  .btn {
    height: 3rem;
  }

  .fill-row {
    width: 100%;
  }

  .side-col {
    flex: 0 2 auto !important;
    width: auto;
    padding-right: 0;
    padding-left: 0;
    margin-right: 1.5rem;
    margin-left: 1rem;
  }

  .base-NumberInput-root {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    height: 100%;

    .input-element {
      text-align: center;
      border-width: 2px;
      border-style: solid;
      border-color: lighten(desaturate($vf-blue, 60%), 25%);
      color: $vf-darkblue;
      height: 3rem;
    }

    .base-NumberInput-input {
      width: 3.5rem;
      font-weight: bold;
      font-size: x-large;
    }

    .btn-number-input {
      width: 3rem;
      background-color: lighten(desaturate($vf-blue, 60%), 25%);
      font-weight: thin;
      font-size: 3rem;
      padding: .75rem;
      padding-bottom: 1.5rem;
      display: inline-flex;
      align-items: center;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;

      &:disabled {
        background-color: lighten(desaturate($vf-blue, 60%), 35%);
        border-color: lighten(desaturate($vf-blue, 60%), 35%);
        color: lighten($vf-darkblue, 20%)
      }

      &:hover:not(:disabled) {
        background-color: lighten(desaturate($vf-blue, 60%), 20%);
        border-color: lighten(desaturate($vf-blue, 60%), 20%);
        color: darken($vf-darkblue, 5%)
      }

      &.increment {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        padding: .625rem;
        padding-bottom: 1rem;
        order: 1;
      }
    }
  }
}

.district-item {

  .training-required {
    background: $vf-yellow;
    margin: 0 -2.2rem;
    padding: 2rem 3rem 2.5rem;

    h4 {
      padding-bottom: 0.5rem;
    }

    .row {
      padding-top: 1rem;
    }
  }
}