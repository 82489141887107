@import '../lib/colors';

.lightbox {
  color: $vf-purple;

  h1 {
    color: $vf-red;
  }

  a {
    color: $vf-purple;
    text-decoration: underline;
  }

  .close {
    color: black;
    cursor: pointer;
  }

  .content-inner {
    text-align: left;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      margin-bottom: 1.2rem;
    }

    ul, li, ol {
      padding: 0;
    }

    ul, ol {
      margin-left: 1.5rem;
    }
  }

  .cta {
    color: white;
    text-decoration: none;
  }

  .secondary-content {
    border-top: 1px solid $gray-200;

    h2 {
      color: $vf-red;
      font-size: 1.2rem;
      font-weight: 500;
    }
  }
}
