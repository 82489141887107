// Bootstrap customization (has to come before BS import)
$enable-negative-margins: true; // https://getbootstrap.com/docs/5.1/utilities/position/#maps
$position-values: ( // https://getbootstrap.com/docs/5.1/utilities/position/#maps
    0: 0,
    50: 50%,
    100: 100%,
    n50: -50%,
    n100: -100%,
);

// Libraries
@import 'bootstrap';
@import 'bootstrap-social/bootstrap-social.css';
@import 'font-awesome';

// Global CSS
@import 'lib/global';
@import 'lib/utilities';

// Components
@import 'page/lightbox';
@import 'big-send/landing';
@import 'blog';
@import 'campaigns';
@import 'dashboard/dashboard';
@import 'districts';
@import 'faq';
@import 'history';
@import 'impact';
@import 'pages/instructions';
@import 'labs';
@import 'landing-page';
@import 'login';
@import 'mapboxgl';
@import 'pages/partner';
@import 'partner-overview';
@import 'progress-bar';

.hidden {
  display:none;
}

.center-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.alert-progress {
  border-radius: 0;

  img {
    width: 35px;
    height: auto;
  }
}
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}
input:not(:checked) + .slider.off-warning {
  background-color: #FF4B4B;
}
.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2c74ff;
}

input:checked + .slider.on-warning {
  background-color: #F03304;
}


input:focus + .slider {
  box-shadow: 0 0 1px #2c74ff;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
