@import '/node_modules/bootstrap/scss/mixins';
@import '/node_modules/bootstrap/scss/functions';
@import '/node_modules/bootstrap/scss/variables';
@import '/node_modules/bootstrap/scss/vendor/rfs';

@import '../lib/colors';
@import '../lib/fonts';

.tbs-landing.on-campus {
  .progress {
    background-color: $tbs-lightblue;
  }
  .progress-bar, .mail-date-reminder {
    background-color: $tbs-lightgreen;
  }
  .highlight, .count-count {
    color: $tbs-lightgreen !important;
  }
  .photo-box {
    .header {
      color: $tbs-lightgreen;
    }
    .btn-primary-lightblue {
      &:hover {
        background-color: $tbs-lightgreen;
        border-color: $tbs-lightgreen;
      }
    }
  }
}

.tbs-landing {
  background-color: $tbs-white;

  header {
    h1 {
      @include body-font;
      color: $tbs-darkblue;
    }

    p {
      @include body-font;
      color: $tbs-darkblue;

      a {
        font-weight: bold;
        color: $tbs-darkblue;
        text-decoration: underline;

        &:hover, &:focus, &:active {
          color: $tbs-lightblue;
        }
      }
    }

    .logo {
      height: 4rem;

      @include media-breakpoint-up(md) {
        height: 6rem;
      }
    }

    .btn-primary-blue {
      background-color: $tbs-lightblue;

      &:hover, &:focus, &:active {
        background: $tbs-darkblue;
        color: white;
      }
    }
  }

  .card {
    background: transparent;
    border: none;

    .card-body {
      padding: 0;
    }
  }

  .mail-date-reminder {
    background-color: $tbs-orange;
    color: $tbs-darkblue;
  }

  #register-user {
    background-color: $tbs-darkblue;

    .card {
      background-color: $tbs-white;
    }

    h2 {
      @include body-font;
      font-weight: bold;
    }

    .login-widget {
      .card {
        background-color: $tbs-white;
        border-radius: 30px;

        article {
          padding: 1rem 1rem 0;
        }

        .card-footer {
          padding: 0.5rem 1rem 1rem;
        }
      }

      h4,
      .instructions {
        background-color: transparent;
        color: $tbs-darkblue;
        text-align: center;
      }

      & > small {
        display: none;
      }
    }
  }

  .volunteer-photos img {
    display: block;
    height: auto;
    width: 100%
  }
}
