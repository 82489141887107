.campaign-landing h2 {
  color: #2C74FF;
}

@media (max-width: 480px) { 
  .campaign-landing h2 {
    font-size: 26px;
  }
}

.campaign-landing .campaigns-description {
  color: #263079;
  font-size: 17px;
  line-height: 26px;
}

.campaign-landing .section {
  margin-bottom: 30px; 
}

.campaign-landing .campaign-header,
.campaign-landing .icon-section {
  width: 50%;
}

@media (max-width: 768px) {
  .campaign-landing .campaign-header,
  .campaign-landing .icon-section {
    width: 100%;
  }

  .campaign-landing .campaign-header .district-icon-legend,
  .campaign-landing .icon-section .district-icon-legend {
    float: none;
  }
}