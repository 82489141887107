@import "../lib/colors";
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';

.photo-box {
  border-radius: 30px;
  background-color: $tbs-darkblue;
  overflow: hidden;
  .image-col {
    padding-left: 0;
    padding-right: 0;  // this is really just for mobile, but it's fine on desktop too
    overflow: hidden;
    img {
      height: 100%;
      max-height: 400px;
    }
  }

  .content-inner {
    width: 100%;
  }

  .content-col {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include media-breakpoint-down(lg) {
    .image-col {
      max-height: 200px;
      img {
        width: 100%;
      }
    }

    .content-col {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }
}
