// Variables

$vf-blue: #2C74FF;
$vf-bluetext: #263079;
$vf-darkblue: #262d7d;
$vf-green: #7DC937;
$vf-lightblue: #00E6FF;
$vf-purple: #262D7D;
$vf-red: #FF5C66;
$vf-yellow: rgb(254, 253, 84);

// Big Send Colors
$tbs-white: #F8F9FA;
$tbs-lightblue: #6478F0;
$tbs-darkblue: #192D6E;
$tbs-orange: #FA9678;
$tbs-lightgreen: #c8fcbe;

// Restated Bootstrap Variables

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$blue: $vf-blue !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #FF4B4B !default;
$red: #FF4B4B !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: $vf-green !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

$primary: $vf-blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $black !default;
