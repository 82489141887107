@import "../lib/colors";
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';

.progress-box > .photo-box {
  .content-col {
    display: flex;
    align-items: center;
    justify-content: center;

    .count-text,
    .count-count {
      font-weight: bold;
      text-align: center;
    }
    .count-text {
      color: $tbs-white;
      font-size: 2.2rem;
      padding-bottom: 10px;
      margin: auto;
      max-width: 500px;  // just makes things break a little better
      .highlight {
        color: $tbs-orange;
      }
    }
    .count-count {
      color: $tbs-orange;
      font-size: 5rem;
      line-height: 1em;
    }
  }

  .progress-container {
    margin-top: 50px;
    width: 90%;
    padding-left: 10%;
  }

  .progress {
    height: 2rem;
    background-color: $tbs-white;
    border: 0;  // undoes parent styling
  }
  .progress-bar {
    background-color: $tbs-orange;
    border-radius: 3rem;  // matches parent
    margin: 0;  // undoes parent styling
  }

  .labels {
    position: relative;
    height: 30px;  // need this because absolute-positioned labels don't have vertical height and then mess up centering 
    
    .label {
      position: absolute;
      color: $tbs-white;
      font-size: 20px;
      font-weight: bold;
    }
    .min {
      left: 0;
    }
    .mid {
      left: 46%;  // takes a minor tweak off 50% to get aligned
    }
    .max {
      right: 0;
    }
  }

  @include media-breakpoint-down(md) {
    .content-col {
      .count-text {
        font-size: 1.6rem;
      }
      .count-count {
        font-size: 15vw;  // box is full-width now, make numbers fill when 9 digits
      }
    }

    .progress-container {
      margin-top: 30px;
    }

    .progress {
      height: 1rem;
    }
  }
}