@import 'mixins.scss';

.blog {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
    margin: 1rem 0;
  }

  table, th, td {
    border: 1px solid #dfe2ef;
    border-collapse: collapse;
  }

  th, td {
    padding: 6px 13px;
    text-align: left;
    border: 1px solid #dfe2ef;
    border-collapse: collapse;
    table-layout: auto;
  }
}
