form.letter-kit-request {
  .btn-primary-red:disabled,
  .btn-primary-red.disabled {
    &:hover, &:focus, &:active {
      background-color: #ff5c66;
      border-color: #ff5c66;
      color: #fbfbfd;
    }
  }
}
