@import '../lib/colors';

.campaign-labels {
  .campaign-bubble {
    display: inline-block;
    height: 1.5rem;
    width: 1.5rem;
    position: relative;
    margin-left: 0.5rem;
    margin-top: 0.25rem;
    margin-bottom: 0.5rem;
    border-radius: 50%;
    font-weight: bold;
    padding-left: 0.25rem !important;

    .fa {
      margin-left: 0.05rem !important;
      position: absolute;
      bottom: 0.25rem;
    }

    span {
      position: absolute;
      bottom: 0.05rem;
      left: 0.45rem;
    }
  }

  .campaign-label {
    letter-spacing: .04rem;
    display: inline-block;
  }

  .campaign-label.campaign-bubble-label {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    padding: 0.25rem 0.75rem !important;

    &.voter-registration {
      transform: translate(-75%, -50%);
    }
  }

  .campaign-bubble:hover>.campaign-label {
    display: inline-block;
    white-space: nowrap;
    z-index: 99;
  }

  .campaign-label,
  .campaign-bubble {
    background-color: lighten(desaturate($vf-blue, 60%), 20%);
    color: $vf-darkblue;
  }

}