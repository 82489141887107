@import '../lib/global';
@import "../lib/colors.scss";

#letter-kits-overview {
  color: $vf-bluetext;

  p a {
    color: inherit;
    text-decoration: underline;

    &:hover, &:focus, &:active {
      color: $vf-blue;
    }
  }

  h1 {
    font-size: 3rem;
  }

  h3 a {
    color: inherit;
    text-decoration: underline;

    &:hover, &:focus, &:active {
      color: $vf-blue;
    }
  }

  .core-login {
    .card {
      border: 0;
      margin-bottom: 0;
    }

    .card + small {
      display: none;
    }

    .already-signed-up,
    .card-title,
    .instructions {
      @include body-font;
      font-size: 1.2rem;
      font-weight: 700;
    }

    .card-body {
      padding-bottom: 0.5rem;
    }

    .card-footer {
      background: transparent;
    }
  }

  .text-error {
    color: $vf-red;
  }

  .request-kit,
  .how-it-works {
    h2 {
      color: $vf-red;
    }

    p {
      font-size: 1rem;
    }
  }
}
