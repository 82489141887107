@import '../lib/global';
@import '../lib/colors';

#campaigns {
  color: $vf-bluetext;

  p a {
    color: inherit;
    text-decoration: underline;

    &:hover,
    &:focus,
    &:active {
      color: $vf-blue;
    }
  }

  .filter-by-state {
    a {
      color: $vf-darkblue;

      &:hover,
      &:focus,
      &:active {
        color: $vf-blue;
        text-decoration: underline;
      }

      i {
        font-style: normal;
        text-decoration: underline;
      }
    }

    .form-select {
      background-color: $vf-darkblue;
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="%23fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2 5l6 6 6-6"/></svg>'); // https://getbootstrap.com/docs/5.1/forms/select/#variables
      color: $white;
    }
  }

  .campaign {
    .description {
      visibility: hidden;
    }

    &:active,
    &:focus,
    &:hover {
      .description {
        visibility: visible;
      }
    }
  }

  .campaign-progress {
    border-radius: 1rem;
    display: flex;
    height: 0.4rem;
    overflow: hidden; // force rounded corners by cropping it
    background-color: $gray-200;

    .campaign-progress-bar {
      background-color: $vf-bluetext;
      overflow: hidden;
    }
  }
}