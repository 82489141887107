.partner-overview {
  color: #263079;
}

.partner-overview h1 {
  font-size: 50px;
  font-weight: bold;
}

.partner-overview h2 {
  font-size: 30px;
  font-weight: bold;
  color: #eb4d46;
  margin: 45px 0;
}

@media (max-width: 400px) {
  .partner-overview h1 {
    font-size: 40px;
  }
}

.partner-overview h3 {
  margin: 45px 0;
}

.partner-overview h4 {
  font-family: Raleway, sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #76f7fc;
  letter-spacing: .2em;
  text-transform: uppercase;
}

.partner-overview p {
  font-family: Raleway, sans-serif;
  font-size: 17px;
  line-height:26px;
}

.partner-overview hr {
  border-bottom: 1px solid #d5d5d5;
}

.partner-overview #partner-landing-hr {
  margin-top: 0;
}

.partner-overview #partner-screenshot-container {
  display: flex;
  align-items: flex-start;
}

.partner-overview #partner-screenshot {
  width: 70%;
  margin: 0 auto;
}

.partner-overview #dashboard-screenshot,
.partner-overview #dashboard-letters-screenshot {
  padding: 0;
}

@media (max-width: 991px) and (min-width: 768px) {
  .partner-overview #benefits .col-md-3 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.partner-overview .item {
  margin: 20px 0;
}

.partner-overview .item p {
  text-align: center;
  margin-top: 20px;
}

.partner-overview .icon-container {
  background-color: #262D7D;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  display: flex;
  margin: auto;
}

.partner-overview .stamp-icon {
  width: 60%;
  margin: auto;
}

.partner-overview .benefit-icons {
  width: 100%;
  margin: auto;
}

.partner-overview .p-label {
  margin-bottom: 0;
}

.partner-overview .red-block {
  background-color: #eb4d46;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.2);
  margin-top: 3rem;
} 

.partner-overview .white-on-red {
  color: #fbfdfb;
  padding: 3rem 0;
  margin: 0;
  text-align: center;
}

.partner-overview .btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.partner-overview .btn-primary-white {
  background-color: #fbfdfb;
  border-color: #fbfdfb;
  color: #263079;
  border-radius: 40px !important;
  font-weight: bold;
  padding: 1rem 2.5rem;
  font-size: 1rem;
  -webkit-transition: none;
  transition: none;
  box-shadow: none;
  margin-bottom: 3rem;
}

.partner-overview .btn-primary-white:hover {
  background-color: #262d7d;
  border-color: #262d7d;
  color: #fbfbfd;
}

@media (max-width: 768px) { 
  .partner-overview .white-on-red { 
    padding: 3rem 1rem;
    font-size: 25px;
  }

  .partner-overview .btn-container { 
    margin: 0 1rem;
  }

  .partner-overview .btn-primary-white { 
    padding: 1rem;
  }
}

@media (max-width: 400px) { 
  .partner-overview .p-5 {
    padding: 2rem!important;
  }
}