@import './_colors.scss';
@import 'bootstrap/scss/vendor/rfs';

a {
  text-decoration: none;
}

.fs-0 {
  @include font-size(3.2rem);
}

.fs-7 {
  font-size: 0.9rem;
}

.fs-8 {
  font-size: 0.8rem;
}

.fs-9 {
  font-size: 0.7rem;
}

.fs-10 {
  font-size: 0.6rem;
}

p,
ul,
ol,
blockquote {
  font-size: 1.2rem;
}

.hyphens-auto {
  hyphens: auto;
}

.small,
small {
  font-size: 90%;
}

.text-blue {
  color: $vf-blue;
}

.text-darkblue {
  color: $vf-darkblue;
}

.text-tbs-darkblue {
  color: $tbs-darkblue;
}

.text-lightblue {
  color: $vf-lightblue;
}

.text-purple {
  color: $vf-purple;
}

.text-white a {
  color: white;
  text-decoration: underline;
}

.text-gray {
  color: $secondary;

  &.blue-link {
    text-decoration: underline;
  }

  &.disabled {
    color: lighten($secondary, 5%);
  }
}