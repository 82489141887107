@import './fonts';

html,
body,
#root {
  @include body-font;
  height: 100%;
  margin: 0;
  padding: 0;
}
