@import '../lib/colors';

@mixin labs-card {
  .labs-report-card {
    border: 2px solid $vf-purple;
    display: flex;
    border-radius: 10px;
    padding: 1.4rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $vf-purple;
    text-align: center;
    text-decoration: none;

    .title {
      font-weight: 700;
      font-size: 1.4rem;
    }

    &:hover {
      text-decoration: none;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, .4);
    }
  }
}
