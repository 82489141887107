@import '../lib/colors';
@import '../lib/typography';

.q-and-a {
  border-bottom: 1px solid $gray-200;

  &:last-child {
    border-bottom: 0;
  }

  h3 {
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
    padding: 1rem 0;

    &:before {
      color: $vf-lightblue;
      float: right;
      font-size: 40px;
      line-height: 26px;
      margin-left: 5px;
    }

    &:first-child:before {
      content: '-';
    }

    &:first-child:last-child:before {
      content: '+';
    }
  }
}
