@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints';
@import 'node_modules/bootstrap/scss/variables';

@import '../lib/colors';
@import '../lib/headings';
@import '../lib/labs-report-cards';
@import '../lib/typography';

.impact2020 {
  @include labs-card;

  color: #263079;

  h1, h2 {
    @include header-font;
  }

  h2 {
    color: #eb4d46;
  }

  h3, .h3 {
    font-weight: bold;
  }

  p {
    a {
      color: #263079;
      text-decoration: underline;
    }

    a:hover {
      color: #007bff;
    }
  }

  .btn-donate {
    background-color: #ff5c66;
    border-color: #ff5c66;
    border-radius: 40px !important;
    box-shadow: none;
    color: #fbfbfd;
    text-decoration: none;
    transition: none;

    &:active {
      background-color: #fca5a2 !important;
      border-color: #fca5a2 !important;
      box-shadow: none !important;
    }

    &:hover {
      background-color: #262d7d;
      border-color: #262d7d;
      color: #fbfbfd;
    }
  }

  .promo-video {
    > div {
      height: 0;
      max-width: 930px;
      padding-bottom: 56.25%;
      position: relative;
    }

    iframe {
      height: 100%;
      left: 0;
      max-height: 523.125px;
      max-width: 930px;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .state-spotlight-garunoff {
    background-color: #263079;
    color: #fff;
    padding: 25px;
  }

  .table {
    td {
      color: #263079;
      font-size: 1.2rem;
    }

    th {
      color: #eb4d46;
      font-size: 1.2rem;
    }

    tr:first-child * {
      border-top: 0;
    }

    @include media-breakpoint-up(sm) {
      td {
        font-size: 2rem;
      }

      th {
        font-size: 1.5rem;
      }
    }

    @include media-breakpoint-up(md) {
      td {
        font-size: 2rem;
      }

      th {
        font-size: 1.75rem;
      }
    }
  }

  .volunteer-quote {
    @include media-breakpoint-up(md) {
      font-size: 1.5rem;
    }
  }

  .volunteer-quote-name {
    color: #eb4d46;
  }
}
