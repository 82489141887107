@import '../lib/colors';

.layout {
  margin-top: 0 !important;
  > div {
    margin-top: 0;
    header {
      z-index: 1;
    }
  }
}

.btn-group-toggle {
  .btn:active,
  .btn:focus,
  .btn:hover,
  .btn.checked {
    background-color: $gray-400;
  }
}

.MuiTableCell-root {
  border-left: 1px solid rgba(224, 224, 224, 1);
  width: auto;
}

.MuiTableCell-head {
  padding: 0 !important;
}
