.dashboard--call-to-action {
  background-color: $vf-purple;
  background-image: url("/images/bg-masthead.png"), url("/images/bg-bloom.png");
  background-blend-mode: normal, color-burn;
  background-size: 70%;
  background-repeat: no-repeat, repeat;
  background-position: center center;
}

.district--card {
  .card-body {
    min-height: 310px;
  }
}

#congratulations {
  background-image: url("/images/bg-blue-wave.jpg");
  background-size: cover;
  background-position: bottom left;
}

#congratulations-card {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
}

.dashboard-stats-panel {
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  font-weight: bold;
  padding-left: 0;
  padding-right: 0;

  h3 {
    color: $vf-lightblue;
    font-weight: bold;
  }

  .stats-row {
    font-size: 3.4rem;
    line-height: 3rem;
    margin-bottom: 1rem;
    color: $vf-lightblue;

    h6 {
      font-size: small;
      color: white;
    }
  }

  .stats-info {
    font-size: 1rem;
    font-weight: normal;
    color: $vf-lightblue;
    color: white;

    a {
      color: $white;
      text-decoration: underline;

      &:hover {
        color: $vf-lightblue;
      }
    }
  }

  .stats-row .col-lg-4,
  .stats-info .col-lg-4 {
    padding-left: 0 3rem 0 0;

    .stacked {
      display: block;
    }

    .side-by-side {
      display: none;
    }

    .side-by-side {
      @media (min-width: 1200px) {
        display: block;
      }
    }

    .stacked {
      @media (min-width: 1200px) {
        display: none;
      }
    }
  }

  hr {
    border-top: 1px solid white;
  }
}

.district-picker .icon-section,
.district-picker .campaign-header {
  width: 50%;
}

@media (max-width: 768px) {

  .district-picker .icon-section,
  .district-picker .campaign-header {
    width: 100%;
  }

  .district-picker .icon-section .district-icon-legend,
  .district-picker .campaign-header .district-icon-legend {
    float: none;
  }
}