@import "../lib/colors";



.announcement-banner {
  text-align: center;
  padding: 1rem;
  color: $vf-purple;
  background: $vf-lightblue;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-size: 1rem;
    margin: 0px 20px;
    flex-grow: 4;
  }

  button {
    margin-left: 2rem;
    margin-right: 1rem;
    margin-bottom: auto;
  }

  .take-action-banner {
    text-align: left;
    display: inline-flex;
    align-items: center;
    width: 100%;

    p {
      font-size: 1.5rem;

      a {
        text-decoration-line: underline;
        text-decoration-style: wavy;
        text-decoration-thickness: 0.25rem;
        text-decoration-color: $vf-blue;
      }
    }
  }
}