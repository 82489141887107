@import './colors';

.btn {
  border-radius: 5px;
  font-weight: bold;
  line-height: 1;
  padding: 0.75rem .75rem 1rem;
  transition: none;
}


.btn-primary-v2 {
  margin: 1rem 0.5rem 0.5rem 0.5rem !important;
  font-weight: bold;
  line-height: 1;
  padding: 0.75rem 1.15rem 1rem !important;
  border: none;
  border-radius: 40px;
  font-size: 1.15rem;
  color: $white;
  background-color: $vf-blue;
  float: right;
  margin: 0.5rem;
  white-space: nowrap;
}

.btn-primary-v2:hover {
  background-color: darken($color: $vf-blue, $amount: 10%);
}

.btn-see-all-campaigns {
  margin-top: 0 !important;
  .see-all-campaigns-button-text-lg{
    display: inline;
  }
  .see-all-campaigns-button-text-sm{
    display: none;
  }
  @media (max-width: 480px) {
    float: left !important; 
    font-size: .9rem !important;
    padding: 0.5rem 1rem .75rem !important;
    white-space: wrap !important;
  }
  @media (max-width: 768px) {

    .see-all-campaigns-button-text-lg{
      display: none;
    }
    .see-all-campaigns-button-text-sm{
      display: inline;
    }
  }
  
}

.btn-secondary-v2 {
  margin: 1rem 1rem 0.5rem 1rem;
  font-weight: bold;
  line-height: 1;
  padding: 0.75rem 1.15rem 1rem;
  border: none;
  border-radius: 40px;
  font-size: 1.15rem;
  color: $gray-800;
  background-color: $gray-200;
  float: right;
  margin-bottom: 0.5rem;
  white-space: nowrap;
}

.btn-secondary-v2:hover {
  color: $gray-900;
  background-color: $gray-300;
}

.btn-secondary-v2:disabled {
  color: $gray-600;
  background-color: $gray-100;
}

.btn.btn-social {
  font-weight: bold;
}

.btn-sm {
  padding: 0.75rem .75rem 1rem;
}

.btn-sm-icon {
  border: none;
  padding: 0.375rem .6rem 0.55rem 0.7rem;
}

.btn-lg {
  // Important necessary to preserve padding when using a col-* class on the button
  padding: 1rem 1rem 1.25rem !important;
}

.btn-primary {
  box-shadow: inset 0 -3px 0 darken($vf-blue, 20%);

  &:active {
    box-shadow: inset 0 3px 0 darken($vf-blue, 20%);
  }

  &:focus {
    background: darken($vf-blue, 5%);
    box-shadow: inset 0 -3px 0 darken($vf-blue, 20%);
  }
}

.btn-light {
  box-shadow: inset 0 -3px 0 rgba(0, 0, 0, 0.2);

  &:active {
    box-shadow: inset 0 3px 0 rgba(0, 0, 0, 0.2);
  }

  &:focus {
    background: darken($vf-blue, 5%);
    box-shadow: inset 0 -3px 0 rgba(0, 0, 0, 0.2);
  }
}

.btn-success {
  box-shadow: inset 0 -3px 0 darken($vf-green, 19%);

  &:active {
    box-shadow: inset 0 3px 0 darken($vf-green, 19%);
  }

  &:focus {
    background: darken($vf-green, 5%);
    box-shadow: inset 0 -3px 0 darken($vf-green, 19%);
  }
}

.btn-primary-red {
  background-color: #ff5c66;
  border-color: #ff5c66;
  border-radius: 40px !important;
  box-shadow: none;
  color: #fbfbfd;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  font-weight: bold;
  height: 3rem;
  padding: 0.75rem 1rem 0.75rem 1rem;
  transition: none;
}

.btn-primary-red:active {
  background-color: #fca5a2 !important;
  border-color: #fca5a2 !important;
  box-shadow: none !important;
  transition: none;
}

.btn-primary-red:hover {
  background-color: #262d7d;
  border-color: #262d7d;
  color: #fbfbfd;
}

.btn-primary-blue {
  background-color: $vf-darkblue;
  border-color: $vf-darkblue;
  border-radius: 40px !important;
  box-shadow: none;
  color: #fbfbfd;
  font-size: 1rem;
  font-weight: bold;
  padding: 1rem 1.5rem;
  -webkit-transition: none;
  transition: none;
}

.btn-primary-blue:active {
  background-color: $vf-lightblue !important;
  border-color: $vf-lightblue !important;
  box-shadow: none !important;
  transition: none;
}

.btn-primary-blue:hover {
  background-color: $vf-red;
  border-color: $vf-red;
}

.btn-primary-white {
  background-color: #fbfdfb;
  border-color: #fbfdfb;
  border-radius: 40px !important;
  box-shadow: none;
  color: $vf-bluetext;
  font-size: 1rem;
  font-weight: bold;
  padding: 1rem 1.5rem;
  -webkit-transition: none;
  transition: none;
}

.btn-primary-white:hover {
  background-color: $vf-red;
  border-color: $vf-red;
  color: #fbfbfd;
}

.btn-primary-lightblue {
  background-color: $vf-lightblue;
  border-color: $vf-lightblue;
  border-radius: 40px !important;
  box-shadow: none;
  color: $vf-darkblue;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 1rem 1.5rem;
  transition: none;
  -webkit-transition: none;
}

.btn-primary-lightblue:active {
  background-color: $vf-blue !important;
  border-color: $vf-blue !important;
  color: $white;
  box-shadow: none !important;
  transition: none;
}

.btn-primary-lightblue:hover {
  background-color: $vf-red;
  border-color: $vf-red;
  color: $vf-darkblue;
}

.btn-danger {
  background: $vf-red;
  box-shadow: inset 0 -3px 0 darken($vf-red, 19%);

  &:active {
    box-shadow: inset 0 3px 0 darken($vf-red, 19%);
  }

  &:focus {
    background: darken($vf-red, 5%);
    box-shadow: inset 0 -3px 0 darken($vf-red, 19%);
  }
}

.btn-check {
  background: #efefef;
  border: 1px solid #ccc;
  border-radius: 50%;
  color: #666;
  height: 36px;
  padding: 0;
  width: 36px;

  .fa {
    display: inline-flex;
  }

  &:hover {
    background: $vf-green;
    border-color: darken($vf-green, 5%);
    color: white;
  }
}

input + .btn {
  border-radius: 40px;
  box-shadow: none;
}

.btn-group .btn + .btn {
  border-left: 1px solid rgba(0, 0, 0, 0.3);
}

.btn-fat-border {
  border: 5px solid $white;
}

.btn-success .fa-arrow-right {
  position: relative;
  top: -1px;
}

.btn-link-underline {
  appearance: none;
  background-color: transparent;
  border: 0;
  color: inherit;
  padding: 0;
  text-decoration: underline;
}

.blue-link {
  appearance: none;
  background-color: transparent;
  border: 0;
  color: #007bff;
  padding: 0;
}

.blue-link:hover {
  color: #263079;
  text-decoration: underline;
}

.btn-rounded {
  border-radius: 40px;
  box-shadow: none;
}
