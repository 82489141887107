.election-history {
  color: $vf-purple;

  h2 {
    color: $vf-red;
    text-transform: uppercase;
    font-size: 1.4rem;
    letter-spacing: 2px;
  }

  a {
    color: $vf-purple;
    text-decoration: underline;
  }

  .total-text {
    font-size: 3rem;
    line-height: 3.2rem;
  }

  .election-history-item {
    .header {
      color: $vf-red;
      border-bottom: 2px solid $vf-red;
      font-size: 2rem;
      padding-bottom: 0.4rem;
      margin-bottom: 2rem;

      button {
        float: right;
        padding: 0.5rem 0 0.7rem;
      }
    }

    .election-history-row, .election-history-row-end {
      font-size: 2rem;
      border-bottom: 1px solid $gray-500;
      margin: 0 0 0.5rem;
      padding-bottom: 0.5rem;

      .col {
        padding: 0;
      }

      .col:last-child {
        text-align: right;
      }
    }

    .election-history-row-end {
      font-weight: bold;
      border-bottom: 0;
    }

    .election-history-item-message {
      margin-top: 2rem;
      
      p {
        font-size: 1rem;
      }

      .lead {
        font-size: 1.6rem;
      }
    }
  }
}