@import 'lib/fonts';

// Mixins
// Background Cover Mixin
@mixin background-cover {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

// Breakpoint Mixin
// (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px)

@mixin blog-content {
  color: $vf-purple;

  h1 {
    font-size: 40px;
    font-weight: bold;
  }

  h2 {
    font-size: 30px;
    font-weight: bold;
    margin: 3rem 0;
  }

  p, ul, li {
    font-size: 1rem;
  }

  .subheader {
    font-weight: 700;
    font-size: 1.2rem;
  }
  .author {
    font-weight: 1.2rem;
    font-style: italic;
    padding-top: 0.5rem;
    padding-bottom: 0rem;
  }
}
