.faq .question .q {
  cursor: pointer;
  padding: 1rem 0;
}

.faq .question .q p {
  margin-bottom: 0;
}

.faq .question .a {
  padding: 1rem 0;
}

.faq .q.collapsed:before,
.faq .q:before {
  font-size: 40px;
  line-height: 26px;
  color: #76f7fc;
  float: right;
  margin-left: 5px;
}

.faq .q.collapsed:before {
  content: '+'; 
}

.faq .q:before {
  content: '-'; 
}