@import 'lib/colors.scss';
@import 'lib/labs-report-cards';
@import 'mixins.scss';

.labs-overview {
  @include blog-content;
  @include labs-card;

  h1 {
    font-size: 50px;
  }

  h2 {
    color: $vf-red;
  }

  .participate {
    font-size: 1.2rem;

    a {
      color: $vf-purple;
      text-decoration: underline;
    }
  }
}
