@import './fonts';

h1,
h2,
.headline {
  @include header-font;
  font-weight: normal;
  font-style: normal;
}

h4,
h5,
h6 {
  @include body-font;
  font-weight: 700;
}
