@import '../lib/colors';

.partners-logo-box {
  align-items: center;
  display: flex;
  justify-content: center;

  .partner-logo {
    max-height: 75px;
    max-width: 90%;
  }
}

.learn-more {
  color: $tbs-darkblue;

  a {
    color: $tbs-darkblue;
    text-decoration: underline;

    &:hover, &:focus, &:active {
      color: $tbs-lightblue;
    }
  }
}
