@font-face {
  font-family: 'Cocogoose';
  src: url('/fonts/Cocogoose.woff2') format('woff2'),
  url('/fonts/Cocogoose.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FontAwesome';
  src: url('/fonts/fontawesome-webfont.eot?v=4.7.0');
  src: url('/fonts/fontawesome-webfont.eot?#iefix&v=4.7.0') format('embedded-opentype'),
  url('/fonts/fontawesome-webfont.woff2?v=4.7.0') format('woff2'),
  url('/fonts/fontawesome-webfont.woff?v=4.7.0') format('woff'),
  url('/fonts/fontawesome-webfont.ttf?v=4.7.0') format('truetype'),
  url('/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal
}

@font-face {
  font-family: 'Raleway';
  src: url('/fonts/Raleway/raleway-500.eot');
  src: url('/fonts/Raleway/raleway-500.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Raleway/raleway-500.woff2') format('woff2'),
  url('/fonts/Raleway/raleway-500.woff') format('woff'),
  url('/fonts/Raleway/raleway-500.svg') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('/fonts/Raleway/raleway-500i.eot');
  src: url('/fonts/Raleway/raleway-500i.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Raleway/raleway-500i.woff2') format('woff2'),
  url('/fonts/Raleway/raleway-500i.woff') format('woff'),
  url('/fonts/Raleway/raleway-500i.ttf') format('truetype'),
  url('/fonts/Raleway/raleway-500i.svg') format('svg');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('/fonts/Raleway/raleway-700.eot');
  src: url('/fonts/Raleway/raleway-700.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Raleway/raleway-700.woff2') format('woff2'),
  url('/fonts/Raleway/raleway-700.woff') format('woff'),
  url('/fonts/Raleway/raleway-700.ttf') format('truetype'),
  url('/fonts/Raleway/raleway-700.svg') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('/fonts/Raleway/raleway-700i.eot');
  src: url('/fonts/Raleway/raleway-700i.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Raleway/raleway-700i.woff2') format('woff2'),
  url('/fonts/Raleway/raleway-700i.woff') format('woff'),
  url('/fonts/Raleway/raleway-700i.ttf') format('truetype'),
  url('/fonts/Raleway/raleway-700i.svg') format('svg');
  font-weight: 700;
  font-style: italic;
}

@mixin body-font {
  font-family: 'Raleway', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

@mixin header-font {
  font-family: 'Cocogoose', Impact, Helvetica, sans-serif;
}

@mixin icon-font {
  font-family: 'FontAwesome';
}

.font-family-body,
.ff-body {
  @include body-font;
}

.font-family-header,
.ff-header {
  @include header-font;
}
