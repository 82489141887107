@import './colors';

.bg-light {
  background-color: #eee;
}

.bg-dark {
  background-color: rgba($black, .9) !important;
  color: $white;
}

.bg-pattern {
  background-blend-mode: color-burn;
  background-color: $vf-purple;
  background-image: url('/images/bg-bloom.png');
  background-repeat: repeat;
  background-size: 400px;
}

.bg-blue {
  background-color: $vf-blue !important;
  color: $white;
}

.bg-lightblue {
  background-color: $vf-lightblue !important;
}

.bg-purple {
  background-color: $vf-purple !important;
  color: $white;
}

.bg-success,
.bg-red {
  background-color: #7dc937 !important;
}

.bg-danger,
.bg-red {
  background-color: $vf-red !important;
}

.bg-dark-blue {
  background-color: $vf-darkblue;
}
