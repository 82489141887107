@import "../lib/colors";

.letter-kit-icon {
  .figure {
    margin: 0.75rem auto 2rem;
    text-align: center;

    > div {
      background-color: $vf-darkblue;
      border-radius: 50%;
      height: 12rem;
      line-height: 12rem;
      margin: 0 auto 0.75rem;
      width: 12rem;
    }
  }

  .figure-caption {
    color: $vf-bluetext;
    font-size: 1rem;
    font-weight: 700;
  }

  .figure-img {
    transform: rotate(-5deg);
    width: 6.5rem;

    &.envelope-icon {
      transform: rotate(5deg);
      width: 7.5rem;
    }
  }
}
