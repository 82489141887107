@import '../lib/colors';

.users-page {
  .date-picker-section {
    margin-top: 30px;
    text-align: left;
    .date-picker {
      margin-right: 20px;
    }
  }
  .stats-section {
    margin-top: 30px;
    text-align: left;
    h4 {
      color: $vf-blue;
      font-weight: bold;
      border-bottom: 1px solid $gray-400;
      padding-bottom: 10px;
      margin-bottom: 20px;
    }
    .stat {
      .stat-itself {
        font-weight: bold;
        display: block;
      }
      label {
        font-weight: normal;
        font-family: Raleway, sans-serif;
      }
    }
    table {
      min-width: 100%;

      .header {
        th {
          font-size: 14px;
        }
      }
      .district-row {
        td {
          font-weight: bold;
          .below-info {
            display: block;
            font-size: 14px;
            font-weight: normal;
          }
          border-bottom: 1px solid $gray-400;
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }

    }
  }
}
