@import "../lib/colors";

.letter-filter {
  border-style: solid;
  border-width: 3px !important;
  border-color: $gray-500  !important;
  width: 100%;
  max-width: 480px;
  float: right;
  white-space: nowrap;

  &:focus-within {
    border-color: $vf-blue  !important;
  }

  i.fa-search {
    width: auto !important;
    font-size: x-large;
    color: $gray-500;
  }

  .decoration-col {
    width: auto !important;
    flex: 0 2 auto !important;
  }

  .clear-filter {
    font-size: medium;
    padding: 0.1rem 1rem;
    margin: 0.1rem 1rem;
  }

  .input-col {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
  }

  input {
    border: none !important;
    margin: 0 0.5rem;

    &:not(:placeholder-shown)~i.fa-search {
      display: none;
    }

    &:placeholder-shown~button {
      display: none;
    }

    &::placeholder {
      font-style: italic;
      color: $gray-500;
    }

    &:focus {
      outline: none;
    }
  }
}

.user-letters {
  color: $vf-darkblue;
}

.letters-col {
  min-width: 25rem !important;
  min-height: 75vh;
}

.empty-letter-column {
  padding: 3rem 0.5rem 0.75rem !important;
  background-color: $gray-100;
  margin-bottom: 1rem;
  border-radius: 0.375rem !important;
  border-width: 3px;
  border-style: solid;
  border-color: $gray-300;
  position: relative;
  height: 8rem;
  text-align: center !important;
}

.bundle-list-item {
  list-style: none;
  padding: 1.25rem 1rem 0.75rem 1rem;
  margin-bottom: 1rem;
  border-radius: 6px !important;
  border-width: 1px;
  border-top-width: 12px;
  border-style: solid;
  border-color: $secondary;
  position: relative;

  &.not-ready {

    .bundle-cell-contents {
      color: lighten($vf-darkblue, 40%);
    }
  }

  .btn-bundle-status {
    border-width: 3px;
    border-style: solid;
    padding-top: calc(.75rem - 3px);
    padding-bottom: calc(.75rem - 3px);
  }

  .cell-label {
    font-size: small;
    font-weight: bold;
    margin-bottom: .75rem;
  }

  &.individual {
    border-top-color: $vf-blue;

    &.not-ready {
      border-top-color: lighten($vf-blue, 20%);

      .cell-label {
        color: lighten($vf-blue, 20%);
      }

      .btn-bundle-status {
        background-color: lighten($vf-blue, 20%);
        border-color: lighten($vf-blue, 20%);

        &.status-secondary {
          color: lighten($vf-blue, 20%);
        }
      }
    }

    .cell-label {
      color: $vf-blue;
    }

    .btn-bundle-status {
      background-color: $vf-blue;
      border-color: $vf-blue;

      &:disabled {
        background-color: lighten($vf-blue, 10%);
        border-color: lighten($vf-blue, 10%);
      }

      &:hover {
        background-color: darken($vf-blue, 5%);
        border-color: darken($vf-blue, 5%);
      }

      &.status-secondary {
        color: $vf-blue;
        background-color: white;

        &:disabled {
          color: lighten($vf-blue, 10%);
        }

        &:hover {
          background-color: $vf-blue;
          color: white;

          &:disabled {
            background-color: lighten($vf-blue, 10%);
          }
        }
      }
    }
  }

  &.party {
    border-top-color: $vf-red;


    &.not-ready {
      border-top-color: lighten($vf-red, 10%);

      .cell-label {
        color: lighten($vf-red, 10%);
      }

      .btn-bundle-status {
        background-color: lighten($vf-red, 10%);
        border-color: lighten($vf-red, 10%);

        &.status-secondary {
          color: lighten($vf-red, 10%);
        }
      }
    }

    .cell-label {
      color: $vf-red;
    }

    .btn-bundle-status {
      background-color: $vf-red;
      border-color: $vf-red;

      &:disabled {
        background-color: lighten($vf-red, 10%);
        border-color: lighten($vf-red, 10%);
      }

      &:hover {
        background-color: darken($vf-red, 5%);
        border-color: darken($vf-red, 5%);
      }

      &.status-secondary {
        color: $vf-red;
        background-color: white;

        &:disabled {
          color: lighten($vf-red, 10%);
        }

        &:hover {
          background-color: $vf-red;
          color: white;

          &:disabled {
            background-color: lighten($vf-red, 10%);
          }
        }
      }
    }
  }

  .letter-info {
    text-align: center;
    font-weight: normal;
    margin-top: .5rem;
    margin-bottom: .75rem;
  }

  @media screen and (max-width: 95rem) {
    .unprepared .fa:not(.fa-spinner) {
      display: none;
    }

    .unprepared .is-loading {
      display: none;
    }

    .btn-bundle-status.btn-primary-v2 {
      padding: 0.75rem !important;
    }

    .download-text {
      display: none !important;
    }
  }

  input[type="checkbox"]:not(:checked)~div.slide-out-list {
    display: none;
  }

  input[type="checkbox"]:checked~div.slide-out-list {
    display: block;
  }

  input[type="checkbox"]:checked~label>i.fa-chevron-up {
    display: inline;
  }

  input[type="checkbox"]:not(checked)~label>i.fa-chevron-up {
    display: none;
  }

  input[type="checkbox"]:checked~label>i.fa-chevron-down {
    display: none;
  }

  input[type="checkbox"]:not(checked)~label>i.fa-chevron-down {
    display: inline;
  }

  .download-message {
    span {
      margin: 0;
      padding: 0;
      text-align: center;
      font-size: small;
      font-weight: bold;
      color: $secondary;
    }
  }

  .download-error {
    span {
      color: $vf-red;
    }
  }

  .col-download-link {
    width: auto;
    flex: 0 2 auto !important;
  }

  .letter-text.fill-row {
    margin-top: 0.1rem;
    font-size: 0.8rem;
  }

  .download-link {
    cursor: pointer;
    padding: 0 !important;
    margin: 0 0 0.5rem !important;

    span {
      font-size: medium;
      text-decoration: none !important;
      display: inline-block;
    }

    i.fa {
      font-size: large;
      font-weight: normal;
      text-align: right;
    }

    div {
      white-space: nowrap;
      display: inline-block;
      margin-right: 0;
      padding-right: 0;
    }
  }

  .download-link:hover {
    i.fa-check-circle-o {
      display: none;
    }

    i.fa-arrow-circle-o-down {
      display: none;
    }

    i.fa-arrow-circle-down {
      display: inline-block;
    }
  }

  .download-link:not(:hover) {
    i.fa-arrow-circle-o-down {
      display: inline-block;
      color: $primary;
    }

    i.fa-arrow-circle-down {
      display: none;
    }

    i.fa-check-circle-o {
      display: inline-block;
      color: var(--bs-body-color);
    }

    i.fa-check-circle {
      display: none;
    }

    span {
      color: var(--bs-body-color);
    }
  }

  .campaign-labels {
    margin-right: 1rem;
  }

  .fill-row {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--bs-body-color);
  }



  .fill-auto {
    @extend .fill-row;
    width: auto;
    margin-left: 0;

    &.campaign-labels-1 {
      max-width: 75%;
    }

    &.campaign-labels-2 {
      max-width: 65%;
    }

    &.campaign-labels-3 {
      max-width: 55%;
    }
  }

  .col-auto {
    width: auto;
    display: inline-block;
  }

  .adopted-on {
    width: 80%;
    color: $vf-darkblue;
    font-weight: normal;
    line-height: 20px;
    margin-top: 0;
    @extend .fill-row;
  }

  .row.letter-item {
    margin: 0;
  }

  .side-col {
    flex: 0 2 auto !important;
    width: auto;
    padding-right: 0;
    padding-left: 0;
    margin-left: 1rem;
  }

  .end-col {
    margin-right: 1rem !important;
  }

  .letter-text.side-col {
    margin-right: 0.25rem;
  }

  .bundle-list-item.side-col {
    margin: 0;
  }

  .view-letters-message {
    display: inline !important;
    text-align: center !important;
    white-space: nowrap;
    margin-top: 0.5rem;
  }

  .view-letters-message:hover {
    cursor: pointer;
  }

  .slide-out-arrow {
    color: $vf-blue;
    display: inline;
  }

  .fa {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .indv-voter {
    .fa {
      margin: 0 !important;
      padding-top: 0.125rem !important;
      padding-left: 0.125rem !important;
      font-size: small !important;
    }

    .fa-chevron-left {
      padding-left: 0rem !important;
    }
  }

  .slide-out-toggle {
    display: none;
  }

  .btn {
    margin: 1rem 1rem 1rem 0rem;
    padding: 0.75rem;
  }

  .btn-primary-v2 {
    padding-right: 2rem !important;
    margin-right: 10px;
  }

  .btn-bundle-status {
    margin: 1rem 0rem 0.5rem 0rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem !important;
    font-size: large;
  }

  i {
    font-size: medium;
  }

  .btn-letter-status {
    font-size: 0.9rem;
    padding: 0.25rem 0.375rem !important;
    margin: 0.5rem 0rem 0.5rem 0rem;
    width: 1.5rem;
    height: 1.5rem;
  }

  .mailed-message {
    display: inline !important;
    text-align: center !important;
    white-space: nowrap;
    font-size: x-large;
    padding-left: 15%;
    white-space: nowrap;
    z-index: -1;
  }

  .mailed-message>i {
    color: $vf-lightblue;
    font-size: 2.75rem !important;
    padding-right: 1rem;
    padding-top: 1rem;
    display: inline-block;
  }

  .mailed-message>span {
    padding-top: 0;
    margin-top: 0;
    display: inline-block;
    top: -0.5rem;
    position: relative;
  }

  .letter-text {
    margin: 0;
    padding-top: 1rem;
    font-size: 0.9rem;
    font-weight: normal;
    text-align: left;
  }

  .letter-text>span {
    margin: 0.5rem 0 0 0;
    vertical-align: middle;
    display: inline-block;
  }

  .letter-text>.download-link {
    margin: 0.5rem 0 0 0 !important;
    vertical-align: middle;
    display: inline-block;
  }

  .mail-by-message {
    flex-wrap: wrap;
    color: $vf-darkblue;
    padding-left: 0.25rem;
    margin-top: 0.25rem;
  }

  .mail-by-label {
    display: inline-block;
    background-color: $secondary;
  }

  .mail-by-label.mail-by-bubble-label {
    position: absolute;
    width: 20rem;
    z-index: 10;
    transform: translate(1.5rem, -1.75rem);
    display: none;
    padding-left: 0;
    border-radius: 0.25rem !important;
    white-space: wrap;

    .text-danger {
      //Don't use red danger text in this popover.
      color: white !important;
    }

    p {
      color: $white  !important;
      margin-bottom: 0;
      font-size: 1rem !important;
      padding: 0.25rem 0.75rem !important;
    }
  }

  .mail-by-message:hover>.mail-by-label {
    display: inline-block;
  }
}