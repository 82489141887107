.u-quiet {
  opacity: 0.75;
}

.reset-link {
  color: #000;

  &:active,
  &:focus,
  &:hover {
    color: #000;
    text-decoration: none;
  }
}

.hover-grow {
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  transition-duration: 0.25s;
  transition-property: transform;

  &:active,
  &:focus,
  &:hover {
    transform: scale(1.05);
  }
}
