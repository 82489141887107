// Index/Landing Page styles
// Most of these styles are marketing related for styling the unauthenticated welcome page
// Unauthenticated Masthead
.landing--masthead {
    display: flex;
    padding: 5rem 0;
    position: relative;
    background: url("/images/bg-home-hero.jpg") no-repeat top center;
    background-size: cover;

    h1 {
        font-size: 4rem;
    }

    p {
      font-size: 1.6rem;
    }

    .hero-wrapper {
        min-height: 100%;

        @media (max-width: 991px) {
          text-align: center;

          h1 {
            font-size: 2.5rem;
          }
        }
    }

    h2 {
        color: white;
        padding-top: 3rem;
        text-align: center;
        font-size: 2.2rem !important;
        @media (min-width: 768px) {
          padding-top: 1.5rem;
          font-size: 3rem;
        }
    }
    h3 {
        font-size: 1.5rem;
    }
}

.home--campaigns {
  color: $vf-bluetext;
  text-align: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  @media (min-width: 768px) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

.home--stats {
    .home-stats-img {
        min-height: 18rem;
        background-size: cover;
        background-position: center center;
    }
    .home-stats-text {
        @media (min-width: 576px) {
            h2 {
              font-size: 7rem;
              line-height: 7rem;
            }
        }
        @media (min-width: 768px) {
            h2 {
              font-size: 8rem;
              line-height: 8rem;
            }
        }
    }
    h2 {
      font-size: 6rem;
      line-height: 6rem;
    }
    p {
      font-size: 2rem !important;
      line-height: 2rem;
    }
}

// Bottom Footer
.landing--call-to-action {
    position: relative;
    background: url('/images/bg-blue-wave.jpg') no-repeat bottom center;
    @include background-cover;
    padding-top: 10rem;
    padding-bottom: 10rem;
    h2 {
        font-size: 2.2rem;
        text-shadow: 2px 2px $vf-purple;
    }
}

.landing--event-box {
  .event-box {
    margin: 4rem;

    @media (max-width: 768px) {
      margin: 2rem;
    }
  }
}

.landing--about {
    color: $vf-bluetext;
    padding: 7rem 2rem;
}
.homepage--bigsend {
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
}
.homepage--bigsend {
  .tbs-landing {
    .btn-primary-red:hover {
      background-color: #fbfdfb;
      color: #262d7d;
    }
    @media (min-width: 768px) {
      h2 {
        font-size: 36px;
        line-height: 40px;
      }
      .progress-bar-goal{
        margin-bottom: 40px;
      }
      .btn-primary-red {
        margin-bottom: 40px;
      }
    }
  }
  @media (max-width: 768px) {
    padding-bottom: 2rem;

    .tbs-landing {
      h2 {
          font-size: 26px;
          line-height: 30px;
          margin-bottom: 1.5rem;
      }
      .btn-primary-red {
        width: 95%;
      }
      .logo,
      .card {
        margin-top: 2rem;
      }

    }
  }
}

.hero-form-wrapper {
    padding: 1rem;
    background: white;

    input {
        background: white;
        font-size: 1.2rem;
        padding: 1rem;
        @media (min-width: 576px) {
            padding: 1.5rem;
        }
    }
}

.campaigns-button {
  text-align: center;

  .btn-primary-red {
    line-height: 22px;
  }
}

@media (max-width: 768px) {
  .campaigns-button .btn-primary-red {
    width: 80%
  }
}

@media (min-width: 576px) {
  .campaigns-button .btn-primary-red {
    font-size: 16px;
    height: 60px;
    line-height: 26px;
    padding: 1rem 7rem 1rem 7rem;
  }
}

@media (min-width: 768px) {
  .campaigns-button .btn-primary-red {
    font-size: 18px;
    height: 60px;
    padding: 1rem 4rem 1rem 4rem;
  }
}

@media (min-width: 840px) {
  .campaigns-button .btn-primary-red {
    font-size: 20px;
    font-weight: bold;
    height: 60px;
    line-height: 26px;
    margin-bottom: 25px;
    padding: 1rem 6rem 1rem 6rem;
  }
}
