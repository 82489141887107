@import '../lib/colors';

table.letter-kit-request-summary {
  color: inherit;
  table-layout: fixed;

  td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .btn {
    background-color: $vf-lightblue;
    border-color: $vf-lightblue;
    color: inherit;
  }
}
