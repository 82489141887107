@import 'alerts';
@import 'backgrounds';
@import 'borders';
@import 'buttons';
@import 'colors';
@import 'fonts';
@import 'forms';
@import 'headings';
@import 'layout';
@import 'root';
@import 'typography';

@import 'src/scss/mixins';

li {
  margin-bottom: 0.5rem;
}

// ---------------------------------------------------------------------------
// Bootstrap overrides
//  - Backgrounds
//  - Badges
//  - Borders
//  - Buttons
//  - Form Inputs
//  - Modals
//  - ReactTable
//  - Tables
//  - Text
// ---------------------------------------------------------------------------

// Badges
.badge {
  padding: 0.5rem 0.75rem;
}

// Borders

.bw-1 {
  border-width: 1px !important;
}

.bw-2 {
  border-width: 2px !important;
}

.bw-3 {
  border-width: 3px !important;
}

.highlight-border-top {
  border-top: 8px solid $vf-purple !important;
}

// Modals

.modal {
  background: rgba(0, 0, 0, 0.5);
  display: block;
  z-index: 100000000;
}

.modal-content {
  border-width: 3px;
}

.modal-title {
  padding: 0.5rem 1rem;
}

.modal-body {
  padding: 2rem;
}

// ReactTable
.ReactTable {
  font-size: 0.85rem;
  line-height: 2;
}

.ReactTable .rt-thead.-header {
  font-weight: bold;
}

.ReactTable .rt-td {
  padding: 0.25rem !important;
}

.ReactTable.-highlight .rt-tbody .rt-tr:hover {
  background: fade-out($vf-blue, $amount: .9) !important;
}

.ReactTable .modal-dialog {
  white-space: normal;
}

// Partner agreement reminders

.partner-terms {
  .modal-dialog {
    max-width: 1000px !important;
  }
}

.announcement-banner.partner-terms {
  background: $warning;
}

// Tables

table.table tr:first-child td {
  border-top: none;
}

.friendly-image-row {
  margin-bottom: 10px;
  margin-top: 10px;
  white-space: nowrap;
}

.friendly-image-row .friendly-image {
  max-width: 25%;
}
