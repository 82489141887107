@import "../lib/colors";
@import "/node_modules/bootstrap/scss/mixins";
@import "/node_modules/bootstrap/scss/functions";
@import "/node_modules/bootstrap/scss/variables";

.footer {
  width: 100%;
  background-color: $vf-darkblue;
  .top {
    .left {
      gap: 1rem;
    }
    .logo {
      max-height: 60px;
      margin-left: -8px;
    }
    background-color: $vf-blue;
    padding-top: 2rem;
    padding-bottom: 2rem;
    .links-container {
      margin-top: 3rem;
      gap: 2rem;
      @include media-breakpoint-up(lg) {
        padding: 0 6rem;
      }
      .link-group {
        .link-list {
          flex-wrap: wrap;
          gap: 5px;
          .separator::before {
            color: white;
            content: " / ";
          }
          a {
            color: white;
            text-decoration: underline;
            text-decoration-thickness: 1px;
            text-underline-offset: 2px;
          }
        }
      }
    }
  }
  .bottom {
    background-color: $vf-darkblue;
    padding-top: 2rem;
    padding-bottom: 2rem;
    .container .row {
      gap: 2rem;
      @include media-breakpoint-up(md) {
        gap: 0;
      }

      .copyright-container {
        .social-icons {
          display: flex;
          align-items: center;
          p {
            margin: 0;
          }
        }
        .social-icons {
          li {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .footer-logo-image {
    display: flex;
    height: 100px;
    margin-bottom: 12px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -12px;
    width: 100px;
  }

  @media (min-width: 768px) {
    .footer-logo-image {
      height: 120px;
      width: 120px;
    }
  }
}
