@import "../lib/colors";

@import '/node_modules/bootstrap/scss/mixins';
@import '/node_modules/bootstrap/scss/functions';
@import '/node_modules/bootstrap/scss/variables';

.materials-icons-container {
  .icon-container {
    border-radius: 50%;
    display: flex;
    margin: auto;
    width: auto;
    height: 100%;
    // max-height: auto;
    max-width: 150px;
  }

  .materials-icon {
    margin: auto;
    width: 60%;

    @include media-breakpoint-down(lg) {
      &.crunch {
        margin: 20%;
      }
    }
  }

  .item {
    margin: 20px 0;

    p {
      margin-top: 20px;
      text-align: center;
      font-family: Raleway, sans-serif;
      font-size: 17px;
      line-height: 26px;
    }
  }

  &.dark-on-light {
    .icon-container {
      background: $vf-purple;
    }
    .item {
      p {
        color: $vf-purple;
      }
    }
  }

  &.light-on-dark {
    .icon-container {
      background-color: $vf-blue;
    }
    .item {
      p {
        color: $white;
      }
    }
  }

  &.big-send, &.big-send-on-campus {
    .icon-container {
      background-color: $tbs-lightblue;
    }
    .item {
      p {
        color: $white;
      }
    }
  }
}
