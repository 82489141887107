.progress {
  border-radius: 3rem;
  background:none;
  height: 55px;
  border: 4px solid white;
  position: relative;
}

.progress-bar {
  border-radius: 3rem 0rem 0rem 3rem;
  border: 2px;
  margin-left: 8px;
  margin-top: 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  background-color: #ff5c66;
  font-size: 1rem;
  font-weight: 700;

  &.complete {
    border-radius: 3rem;
  }
}
