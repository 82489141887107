.navbar {
  background-color: rgb(44, 116, 255);
  background-size: 50%;
  background-repeat: no-repeat;
  min-height: 100px;

  .nav-item {
    align-items: center;

    &.active {
      cursor: default;
      text-decoration: underline;
    }
  }

  .nav-link {
    margin-right: 2rem;
    font-weight: 700;
    align-items: center;
    display: flex;
    cursor: pointer;

    &:hover, &:focus, &:active {
      text-decoration: underline;
    }

    &.btn-link {
      text-decoration: none;

      span {
        &:hover, &:focus, &:active {
          text-decoration: underline;
        }
      }
    }

    &.nav-button {
      text-decoration: none;
    }
  }

  .logo {
    height: 60px;
    width: inherit;
    margin-left: 0.25em;
    padding-top: 0;
  }

  .navbar-toggler-icon {
    color: white;
  }

  .navbar-collapse:not(.collapse) {
    text-align: center;
    padding: 2rem;

    .nav-link {
      margin-right: 0;
      justify-content: center;
    }
  }
}
