@import '../lib/colors';

.tbs-landing footer {
  background-color: $tbs-darkblue;

  img {
    height: 5rem;
  }

  p a {
    display: inline-block;
  }
}
