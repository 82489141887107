@import 'colors';

label {
  font-weight: bold;
}

.input-group-text {
  padding-top: 0.25rem;
}

.form-control {
  border-radius: 0;

  &::placeholder {
    color: rgba(0, 0, 0, 0.45);
  }

  &:focus {
    box-shadow: none;

    &::placeholder {
      color: rgba($vf-blue, 0.25);
    }
  }
}

.form-control-lg {
  outline: none;

  &:focus {
    box-shadow: inset 0 -3px 0 rgba(0, 0, 0, 0.15);
  }
}

// FIXME: this is a temporary replacement, as .form-row was removed from Bootstrap 5
// https://stackoverflow.com/a/67655097/10205241
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;

  & > .col,
  & > [class*=col-] {
    padding: 1rem;
  }
}
