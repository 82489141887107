@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';

@import '../lib/colors';

#partner {
  background-color: $tbs-white;

  .logo-col-left {
    padding-right: 2rem;
  }
  .logo-col-right {
    padding-left: 2rem;
    img {
      max-height: 100px;
    }
  }

  @include media-breakpoint-down(sm) {
    .vertical-line-right {
      border-right: none;
    }
    .logo-col-left {
      padding: 3rem 4rem 1rem 4rem;
    }
    .logo-col-right {
      padding: 1rem 4rem 3rem 4rem;
      text-align: center;
    }
    .logo-col-left,
    .logo-col-right {
      img, 
      svg {
        max-height: 80px;;
      }
    }
  }

  .description-col {
    hr {
      margin-top: 40px;
    }
    .disclaimer {
      font-size: 0.9rem;
    }
    .disclaimer-list {
      list-style: lower-roman;
    }
  }

  .login-widget {
    h4,
    .instructions {
      color: $vf-blue;
      font-size: 1.5rem;
      font-weight: 700;
      text-align: left;
    }

    small.text-white {
      display: none;
    }
  }

  .core-login {
    .card {
      box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .175);
      margin-top: 1rem;
    }

    .card-body {
      padding-bottom: 0.75rem;
    }
  }

  .materials-section {
    text-align: center;

    .materials-icons-container {
      max-width: 1000px;
    }

    p {
      a {
        color: $vf-lightblue;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
