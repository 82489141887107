@import '../lib/colors';
@import '../lib/_buttons.scss';
@import '/node_modules/bootstrap/scss/mixins';
@import '/node_modules/bootstrap/scss/functions';
@import '/node_modules/bootstrap/scss/variables';

.event-box {
  .photo-box {
    background-color: $vf-darkblue;
    .content-inner {
      padding: 0 2rem;
      .header,
      .event-title,
      .event-date {
        font-family: 'Raleway';
      }
      .header {
        font-size: 1.4rem;
        font-weight: bold;
        color: $vf-lightblue;
      }
      .event-title {
        font-weight: bold;
        line-height: 1em;
        font-size: 2rem;
        overflow-wrap: break-word;
      }
      .event-date {
        font-size: 1.5rem;
        line-height: 1em;
      }
      .event-title,
      .event-date {
        color: white;
      }
      .rsvp-button-container {
        width: 100%;
        @include media-breakpoint-up(md) {
          width: 50%;
        }
        .rsvp-button {
          width: 100%;
          line-height: 1rem;
        }
      }
      @include media-breakpoint-up(md) {
        padding: 2rem;
        .header {
          font-size: 2rem;
        }
        .event-title {
          font-size: 3rem;
        }
      }
    }
  }
  &.bigsend {
    .photo-box {
      background-color: $tbs-darkblue;
      .header {
        color: $tbs-orange;
      }
      .event-title,
      .event-date {
        color: $tbs-white;
      }

      .btn-primary-lightblue {
        background-color: $tbs-lightblue;
        border-color: $tbs-lightblue;
        color: $tbs-white;

        &:hover {
          background-color: $tbs-orange;
          border-color: $tbs-orange;
          color: $tbs-darkblue;
        }

        &:active {
          background-color: $tbs-darkblue;
          border-color: $tbs-darkblue;
        }
      }
    }
  }
}
